:root {
  --gray-1: #e0e0e0;
  --gray-2: #e0e0e0;
  --gray-3: #666666;
  --purple: #6a0dad;
  --tiptap-menu-bg: #ffffff;
  --tiptap-menu-border: #e0e0e0;
  --tiptap-menu-shadow: rgba(0, 0, 0, 0.15);
  --tiptap-button-hover: #f5f5f5;
  --tiptap-button-active: #edf2fa;
  --tiptap-button-active-color: #4285f4;
  --tiptap-text: #333333;
}

/* Reset for Tiptap elements to prevent Pico CSS interference */
.tiptap-bubble-menu,
.tiptap-bubble-menu *,
.tiptap-dropdown,
.tiptap-dropdown *,
.tiptap-button,
.tiptap-dropdown-trigger {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Main bubble menu container - with !important to override Pico */
.tiptap-bubble-menu {
  display: flex !important;
  flex-wrap: wrap !important; /* Add this line to enable wrapping */
  align-items: center !important;
  background-color: var(--tiptap-menu-bg) !important;
  border-radius: 6px !important;
  box-shadow: 0 2px 8px var(--tiptap-menu-shadow) !important;
  border: 1px solid var(--tiptap-menu-border) !important;
  padding: 4px !important;
  z-index: 100 !important;
  gap: 4px !important;
}

/* Dropdown container */
.tiptap-dropdown {
  position: relative !important;
  display: inline-block !important;
}

/* Dropdown trigger button */
.tiptap-dropdown-trigger {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 32px !important;
  height: 32px !important;
  padding: 0 8px !important;
  border: none !important;
  background: transparent !important;
  border-radius: 4px !important;
  color: var(--tiptap-text) !important;
  cursor: pointer !important;
  font-size: 0.9rem !important;
  gap: 4px !important;
}

.tiptap-dropdown-trigger:hover {
  background-color: var(--tiptap-button-hover) !important;
}

.tiptap-dropdown-icon {
  font-size: 0.7em !important;
  margin-left: 2px !important;
}

/* Dropdown content */
.tiptap-dropdown-content {
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  min-width: 150px !important;
  background-color: var(--tiptap-menu-bg) !important;
  box-shadow: 0 4px 12px var(--tiptap-menu-shadow) !important;
  border-radius: 4px !important;
  border: 1px solid var(--tiptap-menu-border) !important;
  padding: 4px !important;
  z-index: 1000 !important;
  margin-top: 4px !important;
}

.tiptap-show {
  display: block !important;
}

.tiptap-hide {
  display: none !important;
}

/* Dropdown items */
.tiptap-dropdown-item {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  text-align: left !important;
  padding: 6px 8px !important;
  border: none !important;
  background: transparent !important;
  color: var(--tiptap-text) !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  gap: 8px !important;
  font-size: 0.85rem !important;
  margin: 0 !important;
}

.tiptap-dropdown-item:hover {
  background-color: var(--tiptap-button-hover) !important;
}

.tiptap-dropdown-item.active,
.tiptap-dropdown-item.active span {
  background-color: var(--tiptap-button-active) !important;
  color: var(--tiptap-button-active-color) !important;
}

/* Regular button */
.tiptap-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
  border-radius: 4px !important;
  color: var(--tiptap-text) !important;
  cursor: pointer !important;
  font-size: 0.9rem !important;
}

.tiptap-button:hover {
  background-color: var(--tiptap-button-hover) !important;
}

.tiptap-button .active {
  color: var(--tiptap-button-active-color) !important;
}

/* Color picker specific */
.tiptap-color-picker {
  padding: 6px !important;
  min-width: 100px !important;
}

.tiptap-color-picker input[type="color"] {
  width: 100% !important;
  height: 30px !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
}

/* Regular button in the floating menu */
.tiptap-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
  border-radius: 4px !important;
  color: var(--tiptap-text) !important;
  cursor: pointer !important;
  font-size: 0.9rem !important;
}

.tiptap-button:hover {
  background-color: var(--tiptap-button-hover) !important;
}

.tiptap-button.active {
  background-color: var(--tiptap-button-active) !important;
  color: var(--tiptap-button-active-color) !important;
}

/* Make sure the upload container doesn't have any unwanted padding/margin */
.upload-container {
  padding: 6px 8px !important;
}

/* Disabled buttons */
.tiptap-dropdown-item[disabled] {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.tiptap-dropdown summary {
  list-style: none !important;
}

/* Show a small arrow only in the ::-webkit-details-marker pseudo-element */
.tiptap-dropdown summary::-webkit-details-marker {
  display: none !important;
}

/* Add a custom arrow that appears only when dropdown is open */
.tiptap-dropdown[open] summary::after {
  content: "" !important;
  position: absolute !important;
  top: 100% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 0 !important;
  height: 0 !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-bottom: 5px solid var(--tiptap-menu-bg) !important;
  z-index: 1001 !important;
}

/* Fix file upload button styling */
.tiptap-dropdown-upload {
  padding: 8px !important;
  border-top: 1px solid var(--tiptap-menu-border) !important;
  margin-top: 4px !important;
}
.tiptap-file-upload {
  width: 100% !important;
}

.tiptap-file-input {
  width: 0.1px !important;
  height: 0.1px !important;
  opacity: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  z-index: -1 !important;
}

.tiptap-upload-button {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  padding: 8px 12px !important;
  background-color: var(--tiptap-button-hover) !important;
  border: 1px solid var(--tiptap-menu-border) !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-size: 0.85rem !important;
  gap: 6px !important;
  color: var(--tiptap-text) !important;
  transition: background-color 0.2s ease !important;
}

.tiptap-upload-button:hover {
  background-color: var(--tiptap-button-active) !important;
}

.tiptap-upload-button:disabled {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}

.tiptap-upload-icon {
  font-size: 1em !important;
}

.tiptap-upload-filename {
  font-size: 0.75rem !important;
  margin-top: 4px !important;
  color: var(--tiptap-text) !important;
  opacity: 0.7 !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.tiptap-dropdown-upload {
  padding: 0 !important;
  border-top: none !important;
  margin-top: 0 !important;
}

.tiptap-upload-item {
  border-top: none !important; /* Remove any border to avoid the ruler look */
}

/* Spinning animation for the upload indicator */
.tiptap-spinner {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Fix dropdown styling to make the arrow part of the native details behavior */
.tiptap-dropdown summary {
  list-style: none !important;
}

.tiptap-dropdown summary::-webkit-details-marker {
  display: none !important;
}

/* Add a custom arrow that appears only when dropdown is open */
.tiptap-dropdown[open] summary::after {
  content: "" !important;
  position: absolute !important;
  top: 100% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 0 !important;
  height: 0 !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-bottom: 5px solid var(--tiptap-menu-bg) !important;
  z-index: 1001 !important;
}

.tiptap-upload-button:hover {
  background-color: var(--tiptap-button-active) !important;
}

@media (max-width: 768px) {
  .tiptap-bubble-menu {
    justify-content: center !important;
    padding: 6px !important; /* More padding on mobile */
  }

  /* Optional: make buttons slightly larger on mobile for better touch targets */
  .tiptap-button,
  .tiptap-dropdown-trigger {
    width: 40px !important;
    height: 40px !important;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .tiptap-bubble-menu {
    justify-content: flex-start !important;
    width: 100% !important; /* Take full width */
  }
}
